import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faSpinner } from '@fortawesome/free-solid-svg-icons';
import './Spinner.scss';

interface Props extends PropsWithChildren {
    size?: 'xs' | 'sm' | 'lg' | '4x' | '1x' | '2x' | '3x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x' | undefined;
    className?: string;
    loading?: boolean | boolean[];
    error?: Error;
}

function isLoading(loading?: boolean | boolean[]): boolean {
    if (typeof loading === 'boolean') {
        return loading === true;
    }

    return loading?.includes(true);
}

export function Spinner(props: Props): JSX.Element {
    let className = 'iconRow';
    if (props?.className) {
        className += ' ' + props.className;
    }

    const size = props?.size || 'lg';

    return (
        <React.Fragment>
            <Row className={className}>
                <Col className="text-center">
                    <FontAwesomeIcon icon={faSpinner} spin={true} title="Loading" size={size} />
                </Col>
            </Row>
        </React.Fragment>
    );
}

export function LoadingError(props: Props): JSX.Element {
    let className = 'iconRow';
    if (props?.className) {
        className += ' ' + props.className;
    }

    const size = props?.size || '2x';

    // TODO: add tooltip with more details about the error

    return (
        <React.Fragment>
            <Row className={className}>
                <Col className="text-center">
                    <FontAwesomeIcon icon={faBug} title="Error loading data" size={size} />
                </Col>
            </Row>
        </React.Fragment>
    );
}

export function LoadingSpinner(props: Props): JSX.Element {
    // If something is loading, show the spinner
    const loading = isLoading(props?.loading);
    if (loading) {
        return <Spinner {...props} />;
    }

    return <React.Fragment>{props.children}</React.Fragment>;
}

function needsLoadingIcon(loading?: boolean | boolean[], error?: Error): boolean {
    if (isLoading(loading)) {
        return true;
    }

    return error !== undefined;
}

export function useNeedsLoadingIcon(loading?: boolean | boolean[], error?: Error): boolean {
    const [value, setValue] = useState(needsLoadingIcon(loading, error));

    useEffect(() => {
        const newValue = needsLoadingIcon(loading, error);
        setValue(newValue);
    }, [loading, error]);

    return value;
}
